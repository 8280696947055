import React from "react"

import SEO from "../components/seo"

const Max = () => (
  <div className={"content"}>
    <SEO title="Who's Max Cuap" />
    <h1>Who's Max Cuap</h1>
    <p className={"short"}>
        Max Cuap ist ein Lebemann und Weltenbummler
    </p>
    <p className={"long"}>
    Max Cuap geniesst sein Leben, er reist gerne und viel und lässt sich von all den unterschiedlichen Kulturen inspirieren. Er hat seinen persönlichen Schlüssel zu einem erfüllten Leben definitiv gefunden. Er ist tolerant und offen. Sein Kredo: Leben und Leben lassen. All seine Erlebnisse machen ihn und sein Leben zu einem Blumenstrauss. Und er ist ein Geniesser – er schätzt gutes Essen, trinkt gerne ein Gläschen Wein oder ein Bier und liebt gute Musik.
    </p>
  </div>
)

export default Max